@import url(https://unpkg.com/react-phone-number-input@3.x/bundle/style.css);
@import-normalise;

@font-face {
  font-family: 'Apertura';
  src: url(/static/media/Apertura-500.f781fd49.eot);
  src: url(/static/media/Apertura-500.f781fd49.eot?#iefix) format('embedded-opentype'),
  url(/static/media/Apertura-500.bd8cb71b.svg#Apertura-500) format('svg'),
  url(/static/media/Apertura-500.11c09774.woff2) format('woff2'),
  url(/static/media/Apertura-500.f3f573ba.woff) format('woff'),
  url(/static/media/Apertura-500.9ef51615.otf) format('opentype'),
  url(/static/media/Apertura-500.fc4ea83c.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url(/static/media/IBMPlexSans-300.4ff30222.eot);
  src: url(/static/media/IBMPlexSans-300.4ff30222.eot?#iefix) format('embedded-opentype'),
  url(/static/media/IBMPlexSans-300.64382b0f.svg#IBMPlexSans-300) format('svg'),
  url(/static/media/IBMPlexSans-300.9e4699a2.woff2) format('woff2'),
  url(/static/media/IBMPlexSans-300.626558db.woff) format('woff'),
  url(/static/media/IBMPlexSans-300.65634a26.otf) format('opentype'),
  url(/static/media/IBMPlexSans-300.9d0ca92f.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url(/static/media/IBMPlexSans-400.6e2f33fc.eot);
  src: url(/static/media/IBMPlexSans-400.6e2f33fc.eot?#iefix) format('embedded-opentype'),
  url(/static/media/IBMPlexSans-400.868da768.svg#IBMPlexSans-400) format('svg'),
  url(/static/media/IBMPlexSans-400.92830cef.woff2) format('woff2'),
  url(/static/media/IBMPlexSans-400.7be933ab.woff) format('woff'),
  url(/static/media/IBMPlexSans-400.75ae6668.otf) format('opentype'),
  url(/static/media/IBMPlexSans-400.8da39220.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url(/static/media/IBMPlexSans-500.98397a77.eot);
  src: url(/static/media/IBMPlexSans-500.98397a77.eot?#iefix) format('embedded-opentype'),
  url(/static/media/IBMPlexSans-500.b4dd28a1.svg#IBMPlexSans-500) format('svg'),
  url(/static/media/IBMPlexSans-500.008f7bd4.woff2) format('woff2'),
  url(/static/media/IBMPlexSans-500.9c44eb3b.woff) format('woff'),
  url(/static/media/IBMPlexSans-500.891def4f.otf) format('opentype'),
  url(/static/media/IBMPlexSans-500.01453276.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url(/static/media/IBMPlexSans-600.51a1e15e.eot);
  src: url(/static/media/IBMPlexSans-600.51a1e15e.eot?#iefix) format('embedded-opentype'),
  url(/static/media/IBMPlexSans-600.addabb06.svg#IBMPlexSans-600) format('svg'),
  url(/static/media/IBMPlexSans-600.f2995744.woff2) format('woff2'),
  url(/static/media/IBMPlexSans-600.0bc33013.woff) format('woff'),
  url(/static/media/IBMPlexSans-600.476c215a.otf) format('opentype'),
  url(/static/media/IBMPlexSans-600.bf2f6d39.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url(/static/media/IBMPlexSans-700.bfd03d6d.eot);
  src: url(/static/media/IBMPlexSans-700.bfd03d6d.eot?#iefix) format('embedded-opentype'),
  url(/static/media/IBMPlexSans-700.ef488c1a.svg#IBMPlexSans700) format('svg'),
  url(/static/media/IBMPlexSans-700.4757e5eb.woff2) format('woff2'),
  url(/static/media/IBMPlexSans-700.1229f80d.woff) format('woff'),
  url(/static/media/IBMPlexSans-700.c5af7435.otf) format('opentype'),
  url(/static/media/IBMPlexSans-700.f1b2d07b.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  font-family:
    'IBM Plex Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}

button:focus {
  outline: none;
}

button:active {
  outline: none;
}

body,
html {
  font-size: 1px;
}

@media (min-width: 658px) {
  body,
  html {
    font-size: calc(0.823px + 0.6 * ((100vw - 1250px) / 680));
  }
}

@media (min-width: 830px) {
  body,
  html {
    font-size: calc(0.815px + 0.6 * ((100vw - 1250px) / 680));
  }
}

@media (min-width: 1176px) {
  body,
  html {
    font-size: calc(0.820px + 0.6 * ((100vw - 1250px) / 680));
  }
}

@media (min-width: 1345px) {
  body,
  html {
    font-size: calc(0.825px + 0.6 * ((100vw - 1250px) / 680));
  }
}

@media (min-width: 1572px) {
  body,
  html {
    font-size: 1px;
  }
}

[data-theme=dark] #root {
  background: #121418;
}

