@import-normalise;
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import 'https://unpkg.com/react-phone-number-input@3.x/bundle/style.css';

@font-face {
  font-family: 'Apertura';
  src: url('assets/fonts/Apertura/Apertura-500.eot');
  src: url('assets/fonts/Apertura/Apertura-500.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/Apertura/Apertura-500.svg#Apertura-500') format('svg'),
  url('assets/fonts/Apertura/Apertura-500.woff2') format('woff2'),
  url('assets/fonts/Apertura/Apertura-500.woff') format('woff'),
  url('assets/fonts/Apertura/Apertura-500.otf') format('opentype'),
  url('assets/fonts/Apertura/Apertura-500.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('assets/fonts/IBMPlexSans/IBMPlexSans-300.eot');
  src: url('assets/fonts/IBMPlexSans/IBMPlexSans-300.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-300.svg#IBMPlexSans-300') format('svg'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-300.woff2') format('woff2'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-300.woff') format('woff'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-300.otf') format('opentype'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-300.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('assets/fonts/IBMPlexSans/IBMPlexSans-400.eot');
  src: url('assets/fonts/IBMPlexSans/IBMPlexSans-400.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-400.svg#IBMPlexSans-400') format('svg'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-400.woff2') format('woff2'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-400.woff') format('woff'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-400.otf') format('opentype'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-400.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('assets/fonts/IBMPlexSans/IBMPlexSans-500.eot');
  src: url('assets/fonts/IBMPlexSans/IBMPlexSans-500.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-500.svg#IBMPlexSans-500') format('svg'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-500.woff2') format('woff2'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-500.woff') format('woff'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-500.otf') format('opentype'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-500.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('assets/fonts/IBMPlexSans/IBMPlexSans-600.eot');
  src: url('assets/fonts/IBMPlexSans/IBMPlexSans-600.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-600.svg#IBMPlexSans-600') format('svg'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-600.woff2') format('woff2'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-600.woff') format('woff'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-600.otf') format('opentype'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-600.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('assets/fonts/IBMPlexSans/IBMPlexSans-700.eot');
  src: url('assets/fonts/IBMPlexSans/IBMPlexSans-700.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-700.svg#IBMPlexSans700') format('svg'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-700.woff2') format('woff2'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-700.woff') format('woff'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-700.otf') format('opentype'),
  url('assets/fonts/IBMPlexSans/IBMPlexSans-700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  font-family:
    'IBM Plex Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}

button:focus {
  outline: none;
}

button:active {
  outline: none;
}

body,
html {
  font-size: 1px;
}

@media (min-width: 658px) {
  body,
  html {
    font-size: calc(0.823px + 0.6 * ((100vw - 1250px) / 680));
  }
}

@media (min-width: 830px) {
  body,
  html {
    font-size: calc(0.815px + 0.6 * ((100vw - 1250px) / 680));
  }
}

@media (min-width: 1176px) {
  body,
  html {
    font-size: calc(0.820px + 0.6 * ((100vw - 1250px) / 680));
  }
}

@media (min-width: 1345px) {
  body,
  html {
    font-size: calc(0.825px + 0.6 * ((100vw - 1250px) / 680));
  }
}

@media (min-width: 1572px) {
  body,
  html {
    font-size: 1px;
  }
}

[data-theme=dark] #root {
  background: #121418;
}
